import React from "react";
import "../App.css";
import {firestore} from "../firebase";
import {addDoc,collection} from "@firebase/firestore";

import { useState, useEffect } from "react";
import Axios from "axios";

import { Table, Card, Form ,Row,Col } from "react-bootstrap";

const initialState = {
    ampm: "",
    lucky2: "",
    lucky3: "",
};

function Dashboard(props) {

  const [{ ampm, lucky2, lucky3 }, setState] = useState(initialState);

  // const [details, setDetails] = useState({
  //   selectedNo: "",
  //   number1: "",
  //   number2: "",
  // });

   let oncount = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
  }).split(' ').join('-');


  const [datas, setDatas] = useState([]);

  const handleSubmit =async (e) => {
    e.preventDefault();

    let oncount = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    }).split(' ').join('-');

    //let oncount = "15-Apr-2022";
    const ref = collection(firestore,"sing");
    const refAll = collection(firestore,"all");

        // addDoc(ref,dat).then(
    //   (e)=>{
    //     console.log(e);
    //     //window.location.href = "/dashboard";
    //   }
    // );

    Axios.post(
      "https://strade.123spider.com/api/admin/storeLucky",
      {
        oncount,
        ampm,
        lucky2,
        lucky3,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    ).then((res) => {
        //console.log(res);

        // addDoc(ref,dat).then(
    //   (e)=>{
    //     console.log(e);
    //     //window.location.href = "/dashboard";
    //   }
    // );
        
       //window.location.href = "/dashboard";
        // if(res.status === 200){
          
          Axios.get(
            `https://strade.123spider.com/api/getLucky?oncount=${oncount}`,
  
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              //window.location.href = "/dashboard";
              addDoc(ref,{
                "createdAt": Date(),
                "date": oncount,
                "time": ampm,
                "valOne": lucky2,
                "valTwo": lucky3,
                //"all": res.data.data.lucky[i]
               }).then(
                (e)=>{
                  //console.log(e);
                  window.location.href = "/dashboard";
                }
              ).catch((err)=>{
                  window.location.href = "/dashboard";
              });
              
             
           })
  
        //     .catch((err) => {
        //       console.log(err.message);
        //     });
        // }

        
      }).catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    //let oncount = "15-Apr-2022";
    let oncount = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
  }).split(' ').join('-');
  console.log(oncount);

    Axios.get(
      `https://strade.123spider.com/api/admin/getLucky?oncount=${oncount}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data.data);
        setDatas(res.data.data.lucky);
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      {/* <Navbar /> */}

      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-3">
            <Card>
              <Card.Body>
                <h6 className="mb-3">Date  {oncount}</h6>
                
                <Form onSubmit={handleSubmit}>
                  <Form.Select
                    aria-label="Default select example" 
                    
                    onChange={(e) =>
                      //setDetails({ ...details, selectedNo: e.target.value })
                      {
                        //const { name, value } = e.target;
                      setState((prevState) => ({ ...prevState, ampm: e.target.value }))
                    }
                    }
                  >
                    <option>Select Time</option>
                    <option value="11">11 AM</option>
                    <option value="1">1 PM</option>
                    <option value="3">3 PM</option>
                    <option value="5">5 PM</option>
                    <option value="7">7 PM</option>
                    <option value="9">9 PM</option>
                  </Form.Select>

                  <br className="mt-2"></br>

                  <Row>
                    <Col>
                    <Form.Control
                      maxLength={1}
                      type="text"
                      placeholder="Value One"
                      onChange={(e) =>
                        {
                          if(e.target.value.length  > 1){ 
                            console.log("limit")
                          }else{
                            setState((prevState) => ({ ...prevState,  lucky2: e.target.value }))
                            //setDetails({ ...details, number1: e.target.value })
                          }
                          
                        }
                        
                      }
                    />
                    </Col>
                    <Col>
                    <Form.Control
                      maxLength={1}
                      type="text"
                      placeholder="Value Two"
                      onChange={(e) =>
                        {
                          if(e.target.value.length  > 1){ 
                            console.log("limit")
                          }else{
                            setState((prevState) => ({ ...prevState, lucky3: e.target.value }))
                            //setDetails({ ...details, number2: e.target.value })
                          }
                          
                        }
                      
                      }
                    />
                    </Col>
                  </Row>
                  <br className="mt-2"></br>

                  
                  
                  <button type="submit" className="btn btn-primary input-block-level form-control">
                    Add
                  </button>
                </Form>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-9">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Lucky2</th>
                  <th>Lucky3</th>
                  <th>Set</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, i) => (
                  <tr key={i}>
                    <td>{data.oncount}</td>
                    <td>{data.ampm}</td>
                    <td>{data.lucky2}</td>
                    <td>{data.lucky3}</td>
                    <td>{data.set_value}</td>
                    <td>{data.value_value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
