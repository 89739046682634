import { Navbar, Container, Button } from "react-bootstrap";
import { NavLink, BrowserRouter as Router,
    Switch,
    Route, } from "react-router-dom";
import Users from "../Components/Users";
import Dashboard from "../Components/Dashboard"
import Offdays from "./Offday";

function Nav() {
    
    const handleClick = () => {
        
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('uid');

        window.location.href = "/";
    }

    // const navLinkStyles = ({isActive}) => {
    //     return {
    //         fontWeight : isActive ? "bold" : "normal",
    //     }
    // }

    return (
        <Router>
            <Navbar className="bg-success">
            <Container>
                <Navbar.Brand href="/dashboard" className="text-light font-weight-bold">Singapore Trade</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <NavLink className="btn btn-succcess text-light" to="/dashboard">Dashboard</NavLink>
                    <NavLink className="btn btn-succcess text-light" to="/users">Users</NavLink>
                    <NavLink className="btn btn-succcess text-light" to="/close_days">Close Day</NavLink>
                    <Button onClick={handleClick} className="btn btn-succcess text-light">
                        Logout
                    </Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Switch>
        <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/users">
            <Users />
            </Route>
          <Route path="/close_days">
            <Offdays />
          </Route>

        </Switch>
        </Router>
    );
}

export default Nav;