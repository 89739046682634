import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.css"
import "../node_modules/bootstrap/dist/js/bootstrap.js"

import Navs from "./Components/Navbar"
import Users from "./Components/Users"

import PrivateRoute from './Routes/PrivateRoute'
import PublicRoute from './Routes/PublicRoute'

ReactDOM.render(

  <Router>
    <Switch>

      <PublicRoute component={App} path="/" exact />
      <PrivateRoute component={Navs} path="/dashboard" exact />
      <PrivateRoute component={Users} path="/users" exact />
    </Switch>
  </Router>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();