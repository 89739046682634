import React from "react";
import "../App.css";

import { useState, useEffect } from "react";
import Axios from "axios";
import { FiEdit,FiTrash2 } from 'react-icons/fi';
import { Table, Card, Form, Col ,Row } from "react-bootstrap";
import DeleteConfirmation from "../Components/DeleteConfirmation";


const initialState = {
  name: "",
  password: "",
};

function Users(props) {

  const [{ name, password }, setState] = useState(initialState);

  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };


  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState("");

  // const [details, setDetails] = useState({ name: "", password: "" });
  

  const handleSubmit = (e) => {
  
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }else{
      Axios.post("https://strade.123spider.com/api/admin/register", {
      name,
      password,
    },{
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.status);
        if(res.status === 200){
          Axios.get(
            `https://strade.123spider.com/api/admin/allUser`,
      
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setStatus("Success!");
              setValidated(false);
              setUpdate(false);
              clearState();
              setTimeout(() => {
                setStatus("");
              }, 5000);
              
              //setDatas(res.data.data.lucky);
              //console.log(res.data.data)
              setDatas(res.data.data);
            })
      
            .catch((err) => {
              console.log(err.message);
            });
        }
        

      })
      .catch((err) => {
        console.log(err.message)
      });
    }

    setValidated(true);

    

  }

  function formatDate(date) {
    var d = new Date(date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
  }).split(' ').join('-');
    return d;
}
 




  const [datas, setDatas] = useState([]);
  let uid = sessionStorage.getItem("uid");

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (id) => {
    setStatus("");
    setDeleteMessage(`Are you sure you want to delete?`);
    setUpdateId(id);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(updateId);
    setDisplayConfirmationModal(false);
    Axios.delete(
      `https://strade.123spider.com/api/admin/deleteUser/`+ updateId,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.status);
        //setDatas(res.data.data);
        if(res.status === 200){
          Axios.get(
            `https://strade.123spider.com/api/admin/allUser`,
      
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setStatus("Success")
              setTimeout(() => {
                setStatus("");
              }, 5000);
              console.log(res.status);
              setDatas(res.data.data);
            })
      
            .catch((err) => {
              console.log(err.message);
            });
        }
      })

      .catch((err) => {
        console.log(err.message);
      });
  };



  useEffect(() => {
    

    Axios.get(
      `https://strade.123spider.com/api/admin/allUser`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.status);
        setDatas(res.data.data);
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);

 function handleEditClicks (id,names) {
        //e.preventDefault();    
        setStatus("");
        setUpdate(true);
        console.log(id);  
        console.log(names); 
        setUpdateId(id);
        setState((prevState) => ({ ...prevState, name: names }));
      }

  function handleEditCancel (){
    setStatus("");
    setUpdate(false);
    clearState();
  }  
  
  const updateData = (e)=>{
    console.log(updateId);
    e.preventDefault();
    if(updateId != null){
      Axios.put(
        `https://strade.123spider.com/api/admin/updateUser/` + updateId,{
          name,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      ).then((res)=>{
        if(res.status === 200){
          Axios.get(
            `https://strade.123spider.com/api/admin/allUser`,
      
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setStatus("Success!");
              setValidated(false);
              setUpdate(false);
              clearState();
              setTimeout(() => {
                setStatus("");
              }, 5000);
              
              //setDatas(res.data.data.lucky);
              //console.log(res.data.data)
              setDatas(res.data.data);
            })
      
            .catch((err) => {
              console.log(err.message);
            });
        }
       
      }).catch((err) => {
        console.log(err.message)
      });
    }
  }


  return (
    <>
      {/*\\ <Navbar /> */}

      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-3">
            <Card>
              <Card.Body>
                <h6 className="mb-4">{update ? "Edit User":"Create User"}</h6>
            
                <Form noValidate validated={validated} onSubmit={update? updateData:handleSubmit }>
                <Form.Group  controlId="validationCustom03">
                  <Form.Label>Username</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter username" 
                    required 
                    value={name} 
                    name="name" 
                    onChange={onChange}
                    //onChange={e => setDetails({ ...details, name: e.target.value })}
                    //value={details.name}
                    />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid username.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="mt-1"></div>
                <Form.Group controlId="validationCustom04">
                  <Form.Label>Password</Form.Label>
                  {update ? 
                  <Form.Control 
                    type="password" 
                    placeholder="Enter password" 
                    
                    value={password} 
                    name="password" 
                    onChange={onChange}
                    // onChange={e => setDetails({ ...details, password: e.target.value })}
                    // value={details.password}
                  /> :
                   <Form.Control 
                    type="password" 
                    placeholder="Enter password" 
                    required 
                    value={password} 
                    name="password" 
                    onChange={onChange}
                    // onChange={e => setDetails({ ...details, password: e.target.value })}
                    // value={details.password}
                  />}
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid password.
                  </Form.Control.Feedback>
                </Form.Group>

                  <br className="mt-1"></br>
                  {update ?  <Row>
                    <Col>
                    <button onClick={handleEditCancel} className="btn btn-secondary input-block-level form-control">
                    Cancel
                  </button>
                    </Col>
                    <Col>
                    <button type="submit" className="btn btn-primary input-block-level form-control">
                    Edit
                  </button>
                    </Col>
                  </Row> :
                  <button type="submit" className="btn btn-primary input-block-level form-control">Add</button>
                  
                  }
                 
                 
                  <br className="mt-3"></br>

                </Form>
                {status === "" ? <br className="mt-1"></br> : 
                <>
                  <br className="mt-1"></br> 
                 <h6 className="mt-1 text-success">{status}</h6>
                </>
               }
                
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-9">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Userame</th>
                  <th>CreatedAt</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, i) => (
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{data.name}</td>
                    <td>{formatDate(data.created_at ?? "")}</td>
                    <td>
                      <div className="col-md-7">
                      <FiEdit className="icon" 
                        onClick={(event)=>handleEditClicks(data.id,data.name)}
                          style={{
                            color: 'blue',
                            top: '20px',
                            right: '20px',
                          }}
                          size="25px" />
                        
                      </div>
                       </td>
                    <td> 
                      {
                        String(data.id) === String(uid) ? <div>Current Login</div> :
                        <div className="col-md-7">
                        <FiTrash2 className="icon" 
                        onClick={() => showDeleteModal(data.id)}
                          style={{
                            color: 'red',
                            top: '20px',
                            right: '20px',
                          }}
                          size="25px"/> 
                        
                      </div>
                      }
                    
                    
                       </td>
                       
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} message={deleteMessage}  />
    </>
  );
}

export default Users;
